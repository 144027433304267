import * as CONST from '../Constants.js';

export default function Auswahl({ klein, hoch, titel, optionArray, variable, setVariable }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    margin: CONST.MARGINKLEIN,
    backgroundColor: '#5555',
    borderRadius: 8,
    height: CONST.BOXHEIGHT,
    width: klein ? CONST.HALFBOXWIDTH : CONST.BOXWIDTH,
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',

    }}>

      {optionArray.map((option, index) => {
        return <div
          key={'option' + index}
          className={(variable == index) ? 'option-clicked' : 'option'}
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            margin: '4px',
            width: klein ? '44px' : 256 / optionArray.length,
            height: hoch ? '42px' : '28px',
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL
          }}
          onClick={() => setVariable(index)}>
          <div style={{display: 'flex', width: '90%', justifyContent: 'center', alignItems: 'center',}}>
            {option}
          </div>
        </div>
      })}
    </div>
    <div style={{ fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL }}>
      {titel}
    </div>

  </div>
}