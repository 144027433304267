import { useState, useEffect, useMemo } from 'react';
import logo from './logo.png';
import logovanco from './logovanco.png';
import infoicon from './information.png';
import usericon from './user.png';
import questionicon from './question.png';
import spritzenicon from './spritze.png';
import fileicon from './file.png';
import calcicon from './calculator.png';
import './App.css';
import Auswahl from './components/Auswahl';
import Eingabe from './components/Eingabe';
import PatientScreen from './screens/1_PatientScreen'
import * as CONST from './Constants.js';
import SpiegelScreen from './screens/2_SpiegelScreen';
import ErstDosisScreen from './screens/3_ErstDosisScreen';
import ParameterScreen from './screens/x_ParameterScreen';
import SteadyStateScreen from './screens/4_SteadyStateScreen';
import DauerinfusionScreen from './screens/5_DauerinfusionScreen';
import HinweiseScreen from './screens/6_HinweiseScreen';
import Warnhinweis from './components/Warnhinweis';

import { Storage } from '@capacitor/storage';

const APPCOLOR = 'rgb(226, 172, 67)'


const setAgreement = async () => {
  await Storage.set({
    key: 'agreed',
    value: 'yes',
  });
};

const checkAgreement = async () => {
  const { value } = await Storage.get({ key: 'agreed' });
  if (!value) {
    return false
  } else {
    return true
  }
};



function App() {
  const [app, setApp] = useState(null)
  const [screen, setScreen] = useState(0)
  const [language, setLanguage] = useState(false)
  const [warnung, setWarnung] = useState(false)
  const [disclaimer, setDisclaimer] = useState(false)
  const [agreement, setAgreement] = useState(false)
  const [status, setStatus] = useState(false)
  const params = new URLSearchParams(window.location.search);
  const [WINHEIGHT, setWINHEIGHT] = useState(window.innerHeight)

  useEffect(() => {
    window.addEventListener('resize', () => setWINHEIGHT(window.innerHeight))
  }, [])

  useEffect(() => {
    async function check() {
      const agreed = await checkAgreement();
      setDisclaimer(agreed)
      console.debug('agreed', agreed)
    }
    check()
  }, [])

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);


  // EINGABE Patientendaten
  const [gender, setGender] = useState(null)
  const [groesse, setGroesse] = useState(null)
  const [unitGroesse, setUnitGroesse] = useState('cm')
  const [gewicht, setGewicht] = useState(null)
  const [filtrationsleistung, setFiltrationsleistung] = useState(null)
  const [vancospiegel, setVancospiegel] = useState(null)
  const [alter, setAlter] = useState(null)


  const [serumkreatinin, setSerumkreatinin] = useState(null)
  const [eGFR, setEGFR] = useState(null)
  const [MHK, setMHK] = useState(2)
  const [medikation, setMedikation] = useState(null)
  const [piperacillinspiegel, setPiperacillinspiegel] = useState(null)
  const [steady, setSteady] = useState(null)

  const n_MHK = (MHK == 0 ? 2 : (MHK == 1 ? 4 : (MHK == 2 ? 8 : 16)))
  console.debug('MHK', MHK)
  console.debug('n_MHK', n_MHK)

  useEffect(() => {
    if (params.get("alter")) {
      setAlter(params.get("alter"))
    }
    if (params.get("serumkreatinin")) {
      setSerumkreatinin(params.get("serumkreatinin"))
      setFiltrationsleistung(0)
    }
    if (params.get("egfr")) {
      setEGFR(params.get("egfr"))
      setFiltrationsleistung(1)
    }
    if (params.get("gewicht")) {
      setGewicht(params.get("gewicht"))
    }
    if (params.get("groesse")) {
      setGroesse(params.get("groesse"))
    }
    if (params.get("geschlecht") == 'w') {
      setGender(1)
    }
    if (params.get("geschlecht") == 'm') {
      setGender(0)
    }
  }, [])

  useEffect(() => {
    if (piperacillinspiegel == 1) {
      setStatus(language ? 'Empiric dosing' : 'Empirische Dosierung')
    } else if (piperacillinspiegel == 0) {
      setStatus(language ? 'Dosage' : 'Dosierung')
      if (medikation == 0) {
        setStatus(language ? 'New dose intermittent infusion' : 'Intermittierende Gabe')
        if (steady == 0) {
          setStatus(language ? 'Adjusted dosing intermittent infusion - first dose' : 'Dosisanpassung intermittierende Gabe - Erstdosis')
        } else if (steady == 1) {
          setStatus(language ? 'Adjusted dosing intermittent infusion - steady-state' : 'Dosisanpassung intermittierende Gabe - Steady State')
        }
      } else if (medikation == 1) {
        setStatus(language ? 'Adjusted dosing continuous infusion' : 'Dosisanpassung Dauerinfusion')
      }

    }
  }, [steady, piperacillinspiegel, medikation, screen])

  useEffect(() => {
    if (screen == 0) {
      setStatus(language ? 'Patient data' : 'Patientendaten')
    }
  }, [screen])

  // A
  const [infusionsdauer, setInfusionsdauer] = useState(null)
  const [erhaltungsdosis, setErhaltungsdosis] = useState(null)

  const [erstdosis, setErstDosis] = useState(null)
  const [infusionsdauerErstDosis, setInfusionsdauerErstDosis] = useState(null)
  const [geplanteErhaltungsdosis, setGeplanteErhaltungsdosis] = useState(null)
  const [geplanteInfusionsdauer, setGeplanteInfusionsdauer] = useState(null)
  const [serumspiegel, setSerumspiegel] = useState(null)
  const [stundennachgabe, setStundennachGabe] = useState(null)

  const [dosisintervall, setDosisIntervall] = useState(null)
  const [neueErhaltungsdosis, setNeueErhaltungsdosis] = useState(null)
  const [neueInfusionsdauer, setNeueInfusionsdauer] = useState(null)

  const initialdosis = language ? 2000 : 4000  //TODO: stimmt die Umrechnung von 4000 zu 2000?

  // Populationsparameter  
  const P_verteilungsvolumen = 0.25
  const P_piperacillinCl = 13
  const P_piperacillinhalbwertszeit = 1
  const P_eliminationskonstante = Math.log(2) / P_piperacillinhalbwertszeit

  const Q0 = 0.3 //Achtung: fehlt bei MeroEasy...aber richtige Werte, weil dort P_verteilungsvolumen = 0.25 und Q0 = 0.25 identisch sind
  // Individuelle kinetische Erwartungswerte
  const E_capQ = (eGFR / 100) * (1. - Q0) + Q0 //das muss auch bei MeroEasy so sein, sonst stimmt die Berechnung nicht. Wert ist aber richtig, s.o.
  console.log('E_capQ', E_capQ)
  const E_piperacillinCl = E_capQ * P_piperacillinCl

  const E_dosisintervall = 13 / E_piperacillinCl * 8
  const idealgewicht = gender == 1 ? (45.5 + ((language ? (groesse * 2.54) : groesse) - 152.4) * 0.89) : (50 + ((language ? (groesse * 2.54) : groesse) - 152.4) * 0.89)
  const E_dosiergewicht = (gewicht < idealgewicht) ? gewicht : (idealgewicht + 0.4 * (gewicht - idealgewicht))
  const E_piperacillinhalbwertszeit = (Math.log(2) * P_verteilungsvolumen * E_dosiergewicht) / E_piperacillinCl
  const E_eliminationskonstante = Math.log(2) / E_piperacillinhalbwertszeit
  const E_verteilungsvolumen = P_verteilungsvolumen * E_dosiergewicht
  const verteilungsvolumen = gewicht * E_verteilungsvolumen

  const lngfaktor = useMemo(() => (language ? 88.4 : 1), [language])
  const krea = useMemo(() => {
    if (serumkreatinin == null) {
      return null
    }
    if (gender == 1) {
      return (serumkreatinin / lngfaktor < 0.6 ? 0.6 : serumkreatinin / lngfaktor)
    } else {
      return (serumkreatinin / lngfaktor < 0.7 ? 0.7 : serumkreatinin / lngfaktor)
    }

  }, [serumkreatinin, gender, lngfaktor])

  ///////////////////////////
  // Intermittierende Gabe //
  ///////////////////////////
  // A. Messung im Steady State
  const [A_geschaetzteHalbwertszeit, setAGeschaetzteHalbwertszeit] = useState(null)
  const A_eliminationskonstante = Math.log(2) / A_geschaetzteHalbwertszeit
  const A_gemessenePiperacillinCl = (Math.log(2) * E_verteilungsvolumen) / A_geschaetzteHalbwertszeit



  const Spitzenspiegel = (dosisintervall) => {
    const dosis = neueErhaltungsdosis ? neueErhaltungsdosis : erhaltungsdosis
    const infdauer = neueInfusionsdauer ? neueInfusionsdauer : infusionsdauer

    const rate = parseFloat(dosis) / parseFloat(infdauer)
    const anteil = E_verteilungsvolumen * A_eliminationskonstante

    let ergebnis = ((rate / anteil)
      * (1. - Math.exp(-A_eliminationskonstante * parseFloat(infdauer)))
      * Math.exp(-A_eliminationskonstante * (6 * dosisintervall - parseFloat(infdauer))))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * parseFloat(infdauer)))
        * Math.exp(-A_eliminationskonstante * (5 * dosisintervall - parseFloat(infdauer))))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * parseFloat(infdauer)))
        * Math.exp(-A_eliminationskonstante * (4 * dosisintervall - parseFloat(infdauer))))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * parseFloat(infdauer)))
        * Math.exp(-A_eliminationskonstante * (3 * dosisintervall - parseFloat(infdauer))))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * parseFloat(infdauer)))
        * Math.exp(-A_eliminationskonstante * (2 * dosisintervall - parseFloat(infdauer))))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * parseFloat(infdauer))));

    return ergebnis
  }

  const computeTalspiegel = (dosisintervall) => {
    const infdauer = neueInfusionsdauer ? neueInfusionsdauer : infusionsdauer
    console.debug('computeTalspiegel', dosisintervall, infdauer)
    const ergebnis = Spitzenspiegel(dosisintervall)
      * Math.exp(-A_eliminationskonstante * (dosisintervall - parseFloat(infdauer)));
    console.debug('computeTalspiegel Ergebnis', ergebnis)
    return ergebnis
  }


  const computeCminMHK = () => {
    const rate = erhaltungsdosis / infusionsdauer
    const anteil = E_verteilungsvolumen * A_eliminationskonstante

    let ergebnis = ((rate / anteil)
      * (1. - Math.exp(-A_eliminationskonstante * infusionsdauer))
      * Math.exp(-A_eliminationskonstante * (6 * dosisintervall - infusionsdauer)))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * infusionsdauer))
        * Math.exp(-A_eliminationskonstante * (5 * dosisintervall - infusionsdauer)))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * infusionsdauer))
        * Math.exp(-A_eliminationskonstante * (4 * dosisintervall - infusionsdauer)))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * infusionsdauer))
        * Math.exp(-A_eliminationskonstante * (3 * dosisintervall - infusionsdauer)))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * infusionsdauer))
        * Math.exp(-A_eliminationskonstante * (2 * dosisintervall - infusionsdauer)))
      + ((rate / anteil)
        * (1. - Math.exp(-A_eliminationskonstante * infusionsdauer))
        * Math.exp(-A_eliminationskonstante * (1 * dosisintervall - infusionsdauer)));
    ergebnis = ergebnis / n_MHK
    console.debug('computeCminMHK', ergebnis)

    return ergebnis
  }

  /*   const talspiegel = useMemo(() => Math.round(computeCminMHK()*n_MHK * 10) / 10,
     [erhaltungsdosis, infusionsdauer,A_eliminationskonstante,dosisintervall])  */

  const talspiegel = useMemo(() => dosisintervall != null ? computeTalspiegel(dosisintervall) : null,
    [erhaltungsdosis, infusionsdauer, A_eliminationskonstante, dosisintervall, gewicht, groesse])

  const cminMHK = (erhaltungsdosis && infusionsdauer && dosisintervall) ? Math.round(talspiegel / n_MHK * 10) / 10 : null;
  //Math.round(computeCminMHK() * 10) / 10 : null;
  console.debug('cminMHK', cminMHK)

  const spitzenspiegel = Math.round(Spitzenspiegel(12) * 10) / 10;
  console.debug('spitzenspiegel', spitzenspiegel)

  // B. Messung nach Erstdosis
  const [geschaetzteHalbwertszeit, setGeschaetzteHalbwertszeit] = useState(null)
  const eliminationskonstante = geschaetzteHalbwertszeit ? Math.log(2) / geschaetzteHalbwertszeit : null
  const gemessenePiperacillinCl = geschaetzteHalbwertszeit ? (Math.log(2) * E_verteilungsvolumen) / geschaetzteHalbwertszeit : null

  // Dauerinfusion  TODO: dosis und laufrate
  const [laufrate, setLaufrate] = useState(null)
  const [infusionsdosis, setInfusionsdosis] = useState(null)

  const cMHK = serumspiegel / n_MHK
  const D_gemessenePiperacillinCl = infusionsdosis ? ((infusionsdosis / 24) / serumspiegel)
    : laufrate ? (((laufrate * 1000. / 2.1) / 24) / serumspiegel) : null;


  // Variablen  
  const patientdata = (gender != null) && groesse && alter && gewicht && eGFR

  const resetAll = () => {
    setGewicht(null)
    setGroesse(null)
    setUnitGroesse('cm')
    setGender(null)
    setFiltrationsleistung(null)
    setVancospiegel(null)
    setAlter(null)
    setSerumkreatinin(null)
    setEGFR(null)
    setMedikation(null)
    setMHK(2)
    setScreen(0)
    setInfusionsdauer(null)
    setErhaltungsdosis(null)
    setDosisIntervall(null)
    setNeueInfusionsdauer(null)
    setNeueErhaltungsdosis(null)
    setGeschaetzteHalbwertszeit(null)
    setLaufrate(null)
    setInfusionsdosis(null)
    setSerumspiegel(null)
    setErstDosis(null)
    setStundennachGabe(null)
    setGeplanteErhaltungsdosis(null)
    setGeplanteInfusionsdauer(null)
    setInfusionsdauerErstDosis(null)
  }

  /*   if (!app) {
  
      return (
        <div className="App" style={{ display: 'flex', alignItems: 'center', overflowY: 'scroll', }}  >
  
          <header className="App-header" 
          style={{ display: 'flex', width: '100%', flexDirection: 'row', minHeight: 80 }}>
            <div style={{
              display: 'flex', marginTop: 0.05, padding: CONST.PADDINGKLEIN, color: 'whitesmoke',
              fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS / 0.9
            }}>
              <div style={{ display: 'flex', color: 'white', fontSize: CONST.SCHRIFTGROSS, fontWeight: 900 }}>
                {language ? ['Antiinfectives', <br />, ' therapeutic drug monitoring']
                  : ['Antiinfektiva ', <br />, ' Dosierung und Therapeutisches Drug Monitoring']}
              </div>
            </div>
          </header>
  
          <div style={{
            display: 'flex', width: '50%', margin: 60,
            flexDirection: matches ? 'row' : 'column', justifyContent: 'space-around', alignItems: 'center',
          }}>
            <div style={{
              display: 'flex', flexDirection: 'column', marginBottom: matches ? 0 : 20,
              color: 'white', fontWeight: 600, fontSize: 20
            }}>
              <img
                src={logo}
                alt="logo"
                style={{ width: 210, height: 210, cursor: 'pointer', paddingBottom: 0 }}
                onClick={() => setApp(true)}
              />
              Piperacillin
            </div>
  
            <div style={{ display: 'flex', flexDirection: 'column', color: 'white', fontWeight: 600, fontSize: 20 }}>
              <a
                href="https://www.vancoeasy.de"
                target='_self'
                rel="noopener"
              >
                <img
                  src={logovanco}
                  alt="logo"
                  style={{ width: 210, height: 210, cursor: 'pointer' }}
                  onClick={() => setApp(true)}
                />
              </a>
              Vancomycin
            </div>
          </div>
  
  
        </div>)
    } */

  return (
    <div className="App"   >
      <div style={{ overflowY: 'scroll', height: WINHEIGHT - 90, }}>
        <header className="App-header"
          style={{
            display: 'flex', width: '100%',
            flexDirection: 'row', minHeight: 80, position: 'fixed', top: 0, left: 0,
          }}>
          <div style={{
            display: 'flex', marginTop: 0.05, padding: CONST.PADDINGKLEIN, color: 'whitesmoke',
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS / 0.9
          }}>
            <div style={{ display: 'flex', color: APPCOLOR, fontSize: CONST.SCHRIFTGROSS, fontWeight: 900 }}>
              {language ? ['Piperacillin dosage and', <br />, ' therapeutic drug monitoring']
                : ['Piperacillin i.v. Dosierung und', <br />, 'Therapeutisches Drug Monitoring']}
            </div>
          </div>
        </header>

        <div style={{
          display: 'flex', width: '100%', /* height: size.height * 0.08, */
          flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          position: 'fixed', top: 80, left: 0, zIndex: 1,
        }}>
          <div style={{
            display: 'flex',
            width: '33%', height: 50, justifyContent: "center",
            alignItems: "center",
            borderRadius: 12, marginLeft: -10, marginTop: CONST.MARGINKLEIN * 2, marginBottom: CONST.MARGINKLEIN * 2,
            padding: CONST.PADDINGKLEIN, color: 'black',
            backgroundColor: APPCOLOR
          }}>
            <div style={{ marginLeft: 10 }}>
              {status}
            </div>
          </div>
        </div>

        {!disclaimer &&
          <div style={{
            backgroundColor: '#282c34',
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
            color: 'white', marginTop: 130,
            padding: '2%',
          }}>
            PipEasy wurde nur für den persönlichen Gebrauch erstellt. Die Verwendung eines von PipEasy erzeugten Ergebnisses erfolgt in jedem Fall auf alleiniges Risiko und Verantwortung des PipEasy-Benutzers. Therapeutische Entscheidungen sollten nicht ausschließlich auf PipEasy beruhen, da die von PipEasy bereitgestellten Informationen die klinische Beurteilung nicht ersetzen.  PipEasy-Benutzer sollten zusätzlich zur Verwendung dieser App und vor medizinischen Entscheidungen einen Arzt konsultieren. Obwohl PipEasy sorgfältig validiert wurde, gibt es keine Garantie für die Richtigkeit der bereitgestellten Ergebnisse. Diese App wird bereitgestellt von Dr. Ute Chiriac, Universitätsklinikum Heidelberg & Dr. Otto Frey, Klinikum Heidenheim. Bevor Sie fortfahren, vergewissern Sie sich bitte, dass Sie den Haftungsausschluss gelesen und verstanden haben.
            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <div
                className='weiterbutton'
                onClick={() => {
                  setAgreement()
                  setDisclaimer(true)
                }}>
                {language ? 'Accept' : 'Akzeptieren'}
              </div>
            </div>
          </div>}

        {disclaimer &&
          <div style={{
            display: 'flex', flexDirection: matches ? 'row' : 'column',
            marginTop: 130, marginBottom: 120,
            alignItems: matches ? 'normal' : 'center',
            //(size.height - size.height * 0.2),
            //overflowY: 'scroll',
          }}>



            <div style={{
              display: 'flex',
              position: matches ? 'relative' : 'fixed',
              bottom: matches ? 0 : 0,
              justifyContent: matches ? 'normal' : 'center',
              width: 80, maxWidth: 80, minWidth: 80,
              flexDirection: matches ? 'column' : 'row',
              fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
              color: 'white',
              alignItems: 'center',
            }}>

              <div className='nav' style={{
                padding: '20%', borderLeftRadius: 4, backgroundColor: '#282c34',
                fontFamily: 'Inter', fontSize: '70%'
              }} /* onClick={() => setApp(false)} */>
                <a
                  href="https://www.doseeasy.de"
                  target='_self'
                  rel="noopener"
                  color='white'
                >
                  <img src={spritzenicon} style={{ width: '80%', aspectRatio: 1, }} alt="spritzenlogo" />
                  <div style={{ width: '10%', fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN, width: '100%', color: 'white' }}> Arzneistoff</div>
                </a>
              </div>


              <div className='nav' style={{
                padding: '20%', borderLeftRadius: 4, backgroundColor: screen == 0 ? APPCOLOR : '#282c34',
                fontFamily: 'Inter', fontSize: '70%'
              }} onClick={() => { setScreen(0) }}>
                <img src={usericon} style={{ width: '100%', aspectRatio: 1 }} alt="patientlogo" />
                <div style={{ width: '10%', fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN, width: '100%' }}> Patient</div>
              </div>
              <div className='nav' style={{ padding: '20%', backgroundColor: screen == 1 || screen == 2 ? APPCOLOR : '#282c34' }}
                onClick={() => {
                  if (medikation != null && piperacillinspiegel == 0) {
                    medikation == 0 && piperacillinspiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'intermittent infusion'])
                    medikation == 0 && piperacillinspiegel == 0 && language == 0 && setStatus('Dosisanpassung intermittierende Gabe')
                    medikation == 1 && piperacillinspiegel == 0 && language && setStatus(['Adjusted dosing', <br />, 'continuous infusion'])
                    medikation == 1 && piperacillinspiegel == 0 && !language && setStatus('Dosisanpassung Dauerinfusion')
                    setScreen(2)
                  }
                  else if (patientdata) {
                    setScreen(1)
                  }
                }}>
                <img src={calcicon} style={{ width: '100%', aspectRatio: 1 }} alt="calc logo" />
                <div style={{ width: '10%', fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN, width: '100%' }}>
                  {language ? 'Calculator' : 'Rechner'}
                </div>
              </div>
              {/*         <div className='nav' style={{ padding: '20%', flex: 1, backgroundColor: screen == 3 ? APPCOLOR : '#282c34' }} onClick={() => { setScreen(3) }}>
          <img src={fileicon} style={{ margin: MARGINKLEIN,width: '100%', aspectRatio:1 }} alt="logo" />
          <div style={{ width: '10%', fontFamily:'Inter', fontSize: '70%',width:'100%'  }}>  {language ? 'PK Data' : 'PK Daten'}</div>
          </div> */}
              <div className='nav' style={{ padding: '20%', borderRightRadius: 4, backgroundColor: screen == 6 ? APPCOLOR : '#282c34' }}
                onClick={() => { setScreen(6) }}>
                <img src={infoicon} style={{ width: '100%', aspectRatio: 1 }} alt="logo" />
                <div style={{ width: '10%', fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN, width: '100%' }}>
                  {language ? 'About' : 'Hinweise'}
                </div>
              </div>
              <div
                className='langbutton'
                style={{ margin: '5%', fontFamily: 'Inter', fontSize: 16, justifyContent: 'center', alignSelf: 'center' }}
                onClick={() => {
                  setLanguage(!language)
                  setWarnung(false)
                  setGewicht(null)
                  setGroesse(null)
                  setUnitGroesse('cm')
                  setGender(null)
                  setFiltrationsleistung(null)
                  setPiperacillinspiegel(null)
                  setAlter(null)
                  setSerumkreatinin(null)
                  setEGFR(null)
                  setMedikation(null)
                }}> {language ? 'DEUTSCH' : 'ENGLISH'} </div>

            </div>

            {((screen < 6 && matches) || (screen == 0 && !matches)) &&
              <PatientScreen setScreen={setScreen} language={language}
                gender={gender} setGender={setGender}
                alter={alter} setAlter={setAlter}
                groesse={groesse} setGroesse={setGroesse}
                gewicht={gewicht} setGewicht={setGewicht}
                E_dosiergewicht={E_dosiergewicht} krea={krea}
                unitGroesse={unitGroesse} setUnitGroesse={setUnitGroesse}
                filtrationsleistung={filtrationsleistung} setFiltrationsleistung={setFiltrationsleistung}
                vancospiegel={vancospiegel} setVancospiegel={setVancospiegel}
                serumkreatinin={serumkreatinin} setSerumkreatinin={setSerumkreatinin}
                eGFR={eGFR} setEGFR={setEGFR}
                medikation={medikation} setMedikation={setMedikation}
                resetAll={resetAll}
              />}

            {patientdata && screen == 2 &&
              <SpiegelScreen setScreen={setScreen} language={language}
                E_piperacillinCl={E_piperacillinCl}
                piperacillinspiegel={piperacillinspiegel} setPiperacillinspiegel={setPiperacillinspiegel}
                serumkreatinin={serumkreatinin} setSerumkreatinin={setSerumkreatinin}
                steady={steady} setSteady={setSteady}
                initialdosis={initialdosis}
                eGFR={eGFR} setEGFR={setEGFR}
                MHK={MHK} setMHK={setMHK}
                medikation={medikation} setMedikation={setMedikation} />}

            {steady == 0 && medikation == 0 && !piperacillinspiegel && screen == 3 &&
              <div>
                <ErstDosisScreen setScreen={setScreen} language={language}
                  erstdosis={erstdosis} setErstDosis={setErstDosis}
                  infusionsdauer={infusionsdauerErstDosis} setInfusionsdauer={setInfusionsdauerErstDosis}
                  geplanteErhaltungsdosis={geplanteErhaltungsdosis} setGeplanteErhaltungsdosis={setGeplanteErhaltungsdosis}
                  geplanteInfusionsdauer={geplanteInfusionsdauer} setGeplanteInfusionsdauer={setGeplanteInfusionsdauer}
                  serumspiegel={serumspiegel} setSerumspiegel={setSerumspiegel}
                  stundennachgabe={stundennachgabe} setStundennachGabe={setStundennachGabe}
                  setUnitGroesse={setUnitGroesse}
                  geschaetzteHalbwertszeit={geschaetzteHalbwertszeit} setGeschaetzteHalbwertszeit={setGeschaetzteHalbwertszeit}
                  E_verteilungsvolumen={E_verteilungsvolumen} n_MHK={n_MHK}
                />
                {gemessenePiperacillinCl &&
                  <Warnhinweis language={language}
                    setWarnung={setWarnung}
                    piperacillincl={gemessenePiperacillinCl}
                    E_piperacillinCl={E_piperacillinCl}
                    cminMHK={false} cMHK={false} />}
              </div>}

            {steady == 1 && medikation == 0 && !piperacillinspiegel && screen == 4 &&
              <div>
                <SteadyStateScreen setScreen={setScreen} language={language}
                  erhaltungsdosis={erhaltungsdosis} setErhaltungsdosis={setErhaltungsdosis}
                  infusionsdauer={infusionsdauer} setInfusionsdauer={setInfusionsdauer}
                  dosisintervall={dosisintervall} setDosisIntervall={setDosisIntervall}
                  serumspiegel={serumspiegel} setSerumspiegel={setSerumspiegel}
                  stundennachgabe={stundennachgabe} setStundennachGabe={setStundennachGabe}
                  neueErhaltungsdosis={neueErhaltungsdosis} setNeueErhaltungsdosis={setNeueErhaltungsdosis}
                  neueInfusionsdauer={neueInfusionsdauer} setNeueInfusionsdauer={setNeueInfusionsdauer}
                  geschaetzteHalbwertszeit={A_geschaetzteHalbwertszeit} setGeschaetzteHalbwertszeit={setAGeschaetzteHalbwertszeit}
                  cminMHK={cminMHK} talspiegel={talspiegel} MHK={n_MHK}
                  computeTalspiegel={computeTalspiegel}
                  setUnitGroesse={setUnitGroesse}
                  E_verteilungsvolumen={E_verteilungsvolumen}
                />

                {cminMHK != null &&
                  <Warnhinweis language={language}
                    setWarnung={setWarnung}
                    piperacillincl={gemessenePiperacillinCl}
                    E_piperacillinCl={E_piperacillinCl}
                    cminMHK={cminMHK} cMHK={false} />}
              </div>}

            {medikation == 1 && piperacillinspiegel == false && screen == 5 &&
              <div>
                <DauerinfusionScreen setScreen={setScreen} language={language}
                  serumspiegel={serumspiegel} setSerumspiegel={setSerumspiegel}
                  dosisintervall={dosisintervall} setDosisIntervall={setDosisIntervall}
                  laufrate={laufrate} setLaufrate={setLaufrate}
                  infusionsdosis={infusionsdosis} setInfusionsdosis={setInfusionsdosis}
                  n_MHK={n_MHK} />

                {cMHK != null &&
                  <Warnhinweis language={language}
                    setWarnung={setWarnung}
                    piperacillincl={D_gemessenePiperacillinCl}
                    E_piperacillinCl={E_piperacillinCl}
                    cminMHK={cminMHK} cMHK={cMHK} />}
              </div>}

            {((screen > 2 && screen < 6) || (screen == 2 && piperacillinspiegel == 1)) &&
              <ParameterScreen screen={screen} setScreen={setScreen} language={language}
                P_verteilungsvolumen={P_verteilungsvolumen} P_piperacillinhalbwertszeit={P_piperacillinhalbwertszeit} P_piperacillinCl={P_piperacillinCl}
                E_verteilungsvolumen={E_verteilungsvolumen} E_piperacillinhalbwertszeit={E_piperacillinhalbwertszeit} E_piperacillinCl={E_piperacillinCl}
                A_geschaetzteHalbwertszeit={A_geschaetzteHalbwertszeit}
                A_gemessenePiperacillinCl={A_gemessenePiperacillinCl} A_eliminationskonstante={A_eliminationskonstante}
                geschaetzteHalbwertszeit={geschaetzteHalbwertszeit}
                gemessenePiperacillinCl={gemessenePiperacillinCl} eliminationskonstante={eliminationskonstante}
                cminMHK={cminMHK} talspiegel={talspiegel} spitzenspiegel={spitzenspiegel}
                serumspiegel={serumspiegel} stundennachgabe={stundennachgabe}
                cMHK={cMHK} D_gemessenePiperacillinCl={D_gemessenePiperacillinCl}
              />}

            {screen == 6 &&
              <HinweiseScreen language={language} />}



          </div>}

      </div>
    </div>
  );
}

export default App;
