
import * as CONST from '../Constants.js';

export default function Ergebnis({ titel, ergebnis, unit, setVariable, variable }) {
    setVariable && setVariable(ergebnis)
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '160px',
      height: '72px',
      padding: titel == 'eGFR' ? 0 : CONST.PADDINGKLEIN,
      margin: CONST.MARGINKLEIN,
      backgroundColor: 'rgba(83, 158, 123, 0.664)',
      borderRadius: 10,
      justifyContent: 'center',
  
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
  
      }}>
        <div
          className={'option-clicked'}
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            height: titel == 'eGFR' ? '28px' : '56px',
            fontFamily:'Inter', fontSize: CONST.SCHRIFTMITTEL,
            width: '96px',
            padding: titel == 'eGFR' ? 0 : CONST.PADDINGGROSS,
            margin: titel == 'eGFR' ? 0 : CONST.PADDINGGROSS
          }} >
          {ergebnis} {unit}
        </div>
      </div>
      <div style={{ marginTop: '4px',  height: '16px', fontFamily:'Inter', fontSize: CONST.SCHRIFTMITTEL }}>
        {titel}
      </div>
  
    </div>
  }