import * as CONST from '../Constants.js';

export default function InfoParameter({
  titel,
  infos, ergebnisse, units,
  variable, setVariable,
  colors,
  zusatzinfos, zusatzergebnisse, zusatzunits,
  warnungCl, warnungauc
}) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    margin: CONST.MARGINKLEIN,
    width: CONST.BOXWIDTH,
    height: 30 + (infos.length + (zusatzinfos ? zusatzinfos?.length : 0)) * 28 + 'px',
    backgroundColor: 'rgb(51, 102, 153)',
    borderRadius: 10,
  }}>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: CONST.MARGINKLEIN,
    }}>
      {infos.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '328px',
            height: '24px',
            marginBottom: CONST.MARGINKLEIN,
          }}>
          <div className={'info'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '307px',
              alignItems: 'center', alignContent: 'center',
              height: '24px',
              color: 'white',
              backgroundColor: colors? colors[index] : (((warnungauc && index == 0) || (warnungCl && index == 1)) ? '#A72134' : 'rgba(122, 122, 122, 0.333)')
            }} >
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'end',
              height: '24px', width: '50%', padding: CONST.PADDINGKLEIN, paddingRight: '8px',
              color: 'black',
              fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN, backgroundColor: 'transparent',
              textAlign: 'right',
              wordBreak: 'normal', whiteSpace: 'pre-wrap'
            }}>
              {info}
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'start',
              fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN, height: '24px',
            }}>
              {ergebnisse[index]} {units[index]}
            </div>

          </div>
        </div>
      })}


      {zusatzinfos?.map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '328px',
            marginBottom: CONST.MARGINKLEIN,
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN,
          }}>
          <div className={'info'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '307px',
              alignItems: 'center', alignContent: 'center',
              height: '24px',
            }} >
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'end',
              height: '24px', width: '50%', padding: CONST.PADDINGKLEIN, paddingRight: '8px',
              color: 'black', fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN,
              textAlign: 'right',
              wordBreak: 'break-word'
            }}>
              {info}
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center', justifyContent: 'start',
              fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN, height: '24px',
            }}>
              {zusatzergebnisse[index]} {zusatzunits[index]}
            </div>

          </div>

        </div>
      })}
    </div>

    <div style={{ color: 'white', fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL }}>
      {titel}
    </div>

  </div>
}