export const SCHRIFTGROSS = 16;
export const SCHRIFTMITTEL = 13;
export const SCHRIFTKLEIN = 11; 
export const SCHRIFTFARBEHELL = 'white';
export const SCHRIFTFARBEDUNKEL = 'black';

export const SCREENWIDTH = '336px'
export const SCREENHEIGHT = '714px'
export const BOXKLEIN = 0 //TODO:
export const PADDINGKLEIN = BOXKLEIN / 20;
export const PADDINGGROSS = BOXKLEIN / 4;
export const MARGINKLEIN = '4px'
export const MARGINGROSS = '8px'
export const BOXWIDTH = '328px'
export const HALFBOXWIDTH = '160px'
export const BOXHEIGHT = 72
export const FIELDWITH = '307px'
export const FIELDHEIGHT = '24px'
export const SMALLFIELDWITH = '96px'

export const GREEN = '#539d7aa9'
export const RED = '#A72134'
export const YELLOW = '#dfaf68'
