import React, { useState, useEffect } from 'react';
import Auswahl from '../components/Auswahl';
import Eingabe from '../components/Eingabe';
import Ergebnis from '../components/Ergebnis';
import * as CONST from '../Constants.js';

export default function PatientScreen({
    language, setScreen,
    gender, setGender, groesse, setGroesse, gewicht, setGewicht,
    E_dosiergewicht, krea,
    unitGroesse, setUnitGroesse, filtrationsleistung, setFiltrationsleistung,
    vancospiegel, setVancospiegel,
    alter, setAlter, serumkreatinin,
    setSerumkreatinin, eGFR, setEGFR, MHK, setMHK,
    medikation, setMedikation,
    resetAll }) {

    const computeEGFR = () => {
        let result = null
        console.debug('krea innen', krea)
        console.debug('dosiergewicht innen', E_dosiergewicht)

        let _serumkreatinin = language ? (Math.min(serumkreatinin, gender ? 0.6 : 0.7) / 88.4)
            : Math.min(serumkreatinin, gender ? 0.6 : 0.7)

        if (gender == 0) {
            result = (((140 - alter) * E_dosiergewicht) / (72 * krea))              //Männlich
        } else {
            result = ((((140 - alter) * E_dosiergewicht) / (72 * krea)) * 0.85)
            console.debug('result', result)
        }
        result = (Math.round(result * 100) / 100).toFixed(0);
        return result
    }

    const computedegfr = serumkreatinin? computeEGFR(): eGFR

    useEffect(() => {
        setEGFR(computedegfr)
    }, [computedegfr])

    console.debug('computedegfr', computedegfr)

    const patientendaten = filtrationsleistung != null && gender != null && alter && groesse && gewicht && (serumkreatinin || eGFR)
    return (<div style={{
        display: 'flex',
        flexDirection: 'column',
        width: CONST.SCREENWIDTH,
        marginTop: CONST.MARGINGROSS,
        fontFamily: 'Inter', fontSize: CONST.SCHRIFTGROSS,
        color: 'white',
    }}>

        <div style={{ display: 'flex', flexDirection: 'row', }}>
            <Auswahl klein titel={language ? 'Gender' : 'Geschlecht'} optionArray={['m', language ? 'f' : 'w']}
                setVariable={setGender} variable={gender}></Auswahl>
            <Eingabe klein language={language} titel={language ? 'Age' : 'Alter'} halb={true} optionArray={['40']}
                setVariable={setAlter} variable={alter} unit={[language ? 'years' : 'Jahre']} min={16} max={110} faktor={[1]}></Eingabe>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Eingabe klein language={language} titel={language ? 'Height' : 'Größe'} halb={true} decimals={language ? 2 : null}
                optionArray={['170']} setVariable={setGroesse} variable={groesse}
                min={language ? 120 / 2.54 : 120} max={language ? 220 / 2.54 : 220}
                setunit={setUnitGroesse} unit={[language ? 'inch' : 'cm']} faktor={[1]}></Eingabe>
            <Eingabe klein language={language} titel={language ? 'Body weight' : 'Gewicht'} halb={true} optionArray={['80']}
                setVariable={setGewicht} variable={gewicht} min={30} max={200} unit={['kg']} faktor={[1]}></Eingabe>
        </div>
        <Auswahl titel={language ? 'Renal function' : 'Filtrationsleistung'} optionArray={[language ? 'Serumcreatinine' : 'Serumkreatinin', 'eGFR']} setVariable={setFiltrationsleistung} variable={filtrationsleistung}></Auswahl>

        <div style={{ display: 'flex', flexDirection: 'row', }}>
            {filtrationsleistung == 0 &&
                <Eingabe klein language={language} titel={language ? 'Serumcreatinine' : 'Serumkreatinin'} halb={true}
                    optionArray={language ? ['50'] : ['5']}
                    setVariable={setSerumkreatinin} variable={serumkreatinin}
                    decimals={1}
                    min={language ? 44 : 0.1} max={language ? 704 : 8}
                    unit={[language ? 'µmol/l' : 'mg/dl']} faktor={[1, 88.4]} />}
            {filtrationsleistung == 0 && !serumkreatinin &&
                <div style={{ display: 'flex', width: CONST.HALFBOXWIDTH, margin: CONST.MARGINKLEIN, backgroundColor: 'transparent' }} />
            }
            {filtrationsleistung == 1 &&
                <Eingabe klein language={language} titel={'eGFR'} optionArray={['100']} halb={true}
                    setVariable={setEGFR} variable={eGFR}
                    decimals={1}
                    min={eGFR ? 0 : 1} max={250}
                    unit={['ml/min']} faktor={[1]} />}

            <div style={{ width: '50%' }}>
                {filtrationsleistung == 0 && gender != null && alter && groesse && serumkreatinin && serumkreatinin > 0 &&
                    ((!language && serumkreatinin.toString().length != 2) || (language && serumkreatinin < 705)) &&

                    <Ergebnis titel={'eGFR'} ergebnis={computedegfr} unit={'ml/min'} setVariable={setEGFR} variable={eGFR}></Ergebnis>}
            </div>
        </div>



        <div style={{ display: 'flex', width: patientendaten ? '100%' : '56.5%', flexDirection: 'row', alignSelf:'flex-start', justifyContent: 'space-evenly' }}>
            <div
                className='resetbutton'
                onClick={resetAll}>
                Reset
            </div>

            {patientendaten &&
                <div
                    className='weiterbutton'

                    onClick={() => {
                        setScreen(2)
                    }}> {language ? 'Next' : 'Weiter'} </div>}
        </div>
    </div>
    )
}