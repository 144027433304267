import * as CONST from '../Constants.js';
import { useState, useRef } from 'react';

export default function Eingabe({ klein, titel, placeh = false, optionArray, variable, setVariable, setunit, unit,
  faktor, min, max, decimals, language, halb = false }) {
  const inputRef = useRef();
  const buttonRef = useRef();

  const [currunit, setCurrunit] = useState(0)
  
  const checkRange = (val) => {
    if (val == '' || val < min * faktor[currunit] || val > max * faktor[currunit]) {
      return false
    } else {
      return true
    }
  }

  const handleChange = (e) => {

    let tvalue

    if (decimals) {
      tvalue = e.target.value.replace(/[^0-9,.]/, '')
      console.log('decimals on')
    } else {
      tvalue = parseInt(e.target.value.replace(/\D/, ''))
    }

    if (tvalue) {
      let lt = tvalue.toString().length
      let geteilterString = tvalue.toString().split(".");
      let nachkommstellen = geteilterString[1] ? geteilterString[1].length : 0


      if (lt > 2 && decimals > 0 && nachkommstellen > decimals) {
        tvalue = tvalue.replace(',', '.') //Wichtig, damit Nachkommastellen mitgenommen werden
        tvalue = parseFloat(tvalue).toFixed(decimals ?? 0)
      } else if (!decimals) {
        tvalue = parseFloat(tvalue).toFixed(0)
      }
      setVariable(tvalue)
    } else {
      setVariable('')
    }


    let inrange = checkRange(tvalue)
    inrange ? inputRef.current.style.color = 'white' : inputRef.current.style.color = '#A72134';

    //(decimals > 0 && tvalue.toString().length == 2) ? inputRef.current.style.color = '#A72134':inputRef.current.style.color = 'white';
  }



  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    margin: CONST.MARGINKLEIN,
    backgroundColor: '#5555',
    height: CONST.BOXHEIGHT,
    borderRadius: 8,
    width: klein ? CONST.HALFBOXWIDTH : CONST.BOXWIDTH,
    justifyContent: 'center',
  }}>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left',
      marginLeft: klein ? '56px' : '140px',
    }}>

      {optionArray.map((option, index) => {
        return <div
          key={'form' + option + index}
          action={'none'}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          {/*  <NumPad.Number value={variable?.replace(',', '.') ?? ''}
            placeholder={placeh && !language ? option : null}
            onChange={(e) => { handleChange(e) }}> */}
          <input
            style={{
              display: 'flex',
              height: '22px',
              width: '40px',
              borderWidth: 2,
              borderColor: 'white',
              color: variable != '' || !isNaN(variable) ? 'white' : 'black',
              backgroundColor: checkRange(variable) ? 'rgba(83, 158, 123, 0.664)' : 'white',
              borderRadius: 8,
              fontFamily:'Inter', fontSize: CONST.SCHRIFTMITTEL, textAlign: 'center'
            }}
            ref={inputRef}
            type="tel"
            //pattern="[0-9]+([\,][0-9]+)?"
            type="text" id={"fnameid" + index}
            value={variable?.replace(',', '.') ?? ''}
            onChange={(e) => { handleChange(e) }}
            onBlur={() => { if (!checkRange(variable)) { setVariable(''); } }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                inputRef.current.blur()
              }
            }}
            placeholder={placeh && !language ? option : null}
            name="fname"
          />
          {/*   </NumPad.Number> */}
          <label style={{
            display: 'flex',
            alignSelf: 'center',
            padding: 4,
            margin: 1,
            color: 'white',
            backgroundColor: 'transparent', //'rgba(122, 122, 122, 0.333)',
            borderRadius: 0,
            fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN,
          }}
            ref={buttonRef}
            type="text" id="fbutton"
            onClick={(e) => {
              setCurrunit(ou => { if (ou < unit.length - 1) { return ou + 1 } else { return 0 } })
            }}
            name="fbutton"
            value={unit[currunit]}
            placeholder={language ? null : option}>{unit[currunit]}</label>

        </div>
      })}

    </div>

    <label style={{ fontFamily:'Inter', fontSize: CONST.SCHRIFTMITTEL, marginTop: CONST.MARGINKLEIN, height: '16px' }}>
      {titel}
    </label>

  </div>
}