import * as CONST from '../Constants.js';

export default function InfoTabelle({ titel, infos, ergebnisse, units, colors, variable, setVariable, zusatz, extra }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: CONST.BOXWIDTH,
    height: extra ? '152px' : '223px',
    maxHeight: extra ? '152px' : '223px',
    justifyContent: 'center',
    margin: CONST.MARGINKLEIN,
    backgroundColor: CONST.GREEN,
    borderRadius: 10,
  }}>

    {/*     <div style={{
      display: 'flex',
      marginTop: CONST.MARGINKLEIN,
      marginBottom: extra? 0: CONST.MARGINKLEIN,
      flexDirection: 'row',
      justifyContent: 'center',
      color: 'black', 
      fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN,
    }} >
      {zusatz}
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily:'Inter', fontSize: CONST.SCHRIFTKLEIN,
      color: 'black',
      marginBottom: extra? CONST.MARGINKLEIN:0 ,
    }} >
      {extra}
    </div> */}

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }} >

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: CONST.FIELDWITH,
          height: CONST.FIELDHEIGHT,
          fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN,
          marginRight: CONST.MARGINKLEIN,
          marginLeft: CONST.MARGINKLEIN,
          marginBottom: CONST.MARGINKLEIN,
          justifyContent: 'space-between',
        }}>

        {infos.map((info, index) => {
          return (<div key={titel + info + index} 
            style={{
              display: 'flex',
              alignItems: 'center',
              width: CONST.SMALLFIELDWITH,
              height: CONST.FIELDHEIGHT,
              padding: 0,
            }}>
            <div
              style={{
                display: 'flex', justifyContent: 'center',
                width: CONST.SMALLFIELDWITH,
                padding: 0,
                color: 'black',
                fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN,
                wordBreak: 'break-all'
              }}>
              {info}
            </div>
          </div>
          )
        })}
      </div>

      {ergebnisse[0].map((info, index) => {
        return <div key={titel + info + index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: CONST.FIELDWITH,
            height: CONST.FIELDHEIGHT,
            fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN,
            marginRight: CONST.MARGINKLEIN,
            marginLeft: CONST.MARGINKLEIN,
            marginBottom: CONST.MARGINKLEIN,
            justifyContent: 'space-between',
          }}>
          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: CONST.SMALLFIELDWITH,
              height: CONST.FIELDHEIGHT,
              color: 'black',
              paddingVertical: 0,
              backgroundColor:colors[index] == CONST.GREEN ? CONST.GREEN : '',
              borderRadius: 8,
            }}>
            <div style={{
              fontSize: CONST.SCHRIFTKLEIN}} >
             {ergebnisse[0][index]} {units[0]}
            </div>

          </div>

          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: CONST.SMALLFIELDWITH,
              height: CONST.FIELDHEIGHT,
              paddingVertical: 0,
              backgroundColor: colors[index] == CONST.GREEN ? CONST.GREEN : '',
              borderRadius: 8,
            }}>
            <div style={{ marginLeft: CONST.MARGINGROSS }}>
              {ergebnisse[1][index]}  {units[1]}
            </div>

          </div>

          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: CONST.SMALLFIELDWITH,
              height: CONST.FIELDHEIGHT,
              paddingVertical: 0,
              backgroundColor: colors[index] ,
              borderRadius: 8,
            }}>
            <div style={{
              fontFamily: 'Inter', fontSize: CONST.SCHRIFTKLEIN
            }}>
              {ergebnisse[2][index]} {units[2]}
            </div>
          </div>

        </div>
      })}
    </div>

    <div style={{ marginVertical: CONST.MARGINKLEIN, fontFamily: 'Inter', fontSize: CONST.SCHRIFTMITTEL, }}>
      {titel}
    </div>

  </div>
}